<template>
	<div>
		<el-table :data="tableData" border style="width: 100%;margin-top: 10px;">
			<el-table-column prop="skuName" label="规格名称" width="140"></el-table-column>
			<el-table-column prop="skuCostPrice" align="center" label="成本价" width="120"></el-table-column>
			<el-table-column prop="skuOriPrice" align="center" label="原价" width="120"></el-table-column>
			<el-table-column prop="skuPrice" align="center" label="零售价" width="120"></el-table-column>
			<!-- <el-table-column prop="skuVipPrice" align="center" label="会员价" width="120"></el-table-column> -->
			<el-table-column prop="skuActualStocks" align="center" label="库存" width="120"></el-table-column>
			<el-table-column prop="skuVolume" align="center" label="商品体积" width="120"></el-table-column>
			<el-table-column prop="skuWeight" align="center" label="商品重量" width="120"></el-table-column>
			<el-table-column prop="status" align="center" label="状态" width="120">
				<template slot-scope="scope">
					<span v-if="scope.row['status']==0">禁用</span>
					<span v-else-if="scope.row['status']==1">启用</span>
					<span v-else>未设置</span>
				</template>
			</el-table-column>
			<el-table-column prop="skuPic" align="center" label="规格展示图片">
				<template slot-scope="scope">
					<el-image
						style="width: 40px; height: 40px;"
						:src="scope.row['skuPic']" 
						:preview-src-list="[scope.row['skuPic']]">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column v-if="commodityStatus==1" fixed="right" align="center" label="操作" width="300">
				<template slot-scope="scope">
					<el-button v-if="scope.row.skuName || (!scope.row.skuName && tableData.length==1)" @click="editSku(scope.row)" size="small" type="infor">编辑</el-button>
					<el-button v-if="scope.row.skuName" @click="delSku(scope.row)" size="small" type="danger">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog append-to-body title="sku-编辑" :visible.sync="dialogVisible">
			<div>
				<EditSku :type="type" :item="selectItem" @schange="handleChangeClose" @fchange="handleChangeConfirm"></EditSku>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { tableColumnConfig, formLabelConfig, formDataProp } from '../../assets/config/store/ProductSku.js';
import EditSku from '../businessComponent/editSku.vue';
import {$vue} from "../../main.js"
export default {
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			dialogVisible: false,
			commodityStatus:1,
			tableData: [],
			selectItem:{},
			title:"新增sku",
			type:1,//默认sku  2其他sku
		};
	},
	components:{
		EditSku:EditSku
	},
	created() {
		this.getDetail();
	},
	methods: {
		editSku(item) {
			this.type=1
			if(item.skuName){
				this.type=2
			}
			this.dialogVisible = true;
			this.title ="编辑sku"
			this.selectItem = item
		},
		delSku(item){
			if(!item.skuName) return this.$message.error("默认sku不允许删除")
			if(this.tableData.length==1) return this.$message.error("请至少保留一条sku")
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				await this.$axios('POST', `/sys/commodity/deleteSku?prodId=${this.selectdata.commodityId}&skuId=${item.skuId}`, {}, true);
				this.$message.success('删除成功！');
				$vue.$emit('fchange', false);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				})
			})
		},
		// async getSkuList() {
		// 	const res = await this.$axios('GET', `/sys/commodity/Skudetail?commmodityId=${this.selectdata.commodityId}`, {}, true);
		// 	this.tableData = res;
		// },
		async getDetail() {
			const res = await this.$axios('POST', '/sys/commodity/detail', { commodityId: this.selectdata.commodityId }, true);
			const {skuList,labelList,commodityStatus} = res;
			this.commodityStatus = commodityStatus
			this.tableData = skuList || [];
		},
		handleChangeClose(){
			this.dialogVisible = false;
		},
		async handleChangeConfirm(e){
			//提交数据this.formData
			let params = {
				commodityId:this.selectdata.commodityId,
				skuList:[e]
			}
			await this.$axios("POST", "/sys/commodity/update", params, true);
			this.$message.success('保存成功！');
			$vue.$emit('fchange', false);
			this.dialogVisible = false;
			// this.getDetail();
		}
	}
};
</script>

<style lang="scss" scoped></style>
