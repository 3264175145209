<template>
	<div>
		<div class="dialog-box">
			<el-form :model="formData" :rules="rules" ref="formData">
				<el-row :gutter="20">
					<el-col :sm="20" :md="item.showType == 'kindEditor' ? 20 : 12" v-for="(item, index) in formDataConfig" v-bind:key="index">
						<el-form-item :label="item.label" :prop="item.prop" :label-width="formLabelWidth">
							<el-input
								v-if="item.showType == 'number'"
								type="number"
								:disabled="item.disabled"
								:readonly="item.readOnly"
								v-model="formData[item.prop]"
								auto-complete="off"
								:placeholder="'请输入' + item.label"
							></el-input>
							
							<el-cascader v-else-if="item.showType == 'cascader'" 
								disabled v-model="formData[item.prop]" 
								:options="item.option" 
								:props="{ expandTrigger: 'hover',value:'propValue',label:'propValue' }"
								>
							</el-cascader>
							
							<ImgUpload
								v-else-if="item.showType == 'imgUploader'"
								@oploadBack="successBack"
								@delcallback="delImgBack"
								:imgurl="uploadImgData"
								:items="item"
							></ImgUpload>
							
							<el-select v-else-if="item.showType == 'select'" :disabled="item.disabled" v-model="formData[item.prop]" placeholder="请选择">
								<el-option
									v-for="(opitem, opindex) in item.option"
									:key="opindex"
									:label="item.lkey ? opitem[item.lkey] : opitem.label"
									:value="item.vkey ? opitem[item.vkey] : opitem.value"
								></el-option>
							</el-select>
							<el-input
								v-else
								:readonly="item.readOnly"
								:disabled="item.disabled"
								v-model="formData[item.prop]"
								autocomplete="off"
								:placeholder="'请输入' + item.label"
							></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<el-button size="small" type="custom" @click="handleChangeConfirm('formData')">确 定</el-button>
		</div>
	</div>
</template>

<script>
	import { formLabelConfig2,formLabelConfig } from '../../assets/config/store/ProductSku.js';
	export default{
		props:{
			type:{
				type:Number,
				default:1
			},
			item:{
				type:Object,
				default:()=>{}
			}
		},
		data(){
			return {
				skuType:"default",
				formLabelWidth:"120px",
				uploadImgData: '',
				muploadImgData: '',
				uploadImg: '',
				oploadFilds: '', //图片字段
				formDataConfig:formLabelConfig, //表单label配置
				formData:{},
				rules:{}
			}
		},
		watch:{
			type(val){
				console.log(val)
			},
			item(val){
				this.formData = val
				if(val.skuName && typeof(val.skuName)==='string')
					this.formData.skuName =val.skuName.split(',')
				if(!this.item.skuName){
					this.formDataConfig = formLabelConfig
				}else{
					this.formDataConfig = formLabelConfig2
				}
			}
		},
		components:{
			ImgUpload: () => import('@/components/imgUpload/imgUploader.vue'),
		},
		created() {
			this.formData = this.item || {}
			if(this.item.skuName && typeof(this.item.skuName)==='string')
				this.formData.skuName = this.item.skuName.split(',')
			if(!this.item.skuName){
				this.formDataConfig = formLabelConfig
			}else{
				this.formDataConfig = formLabelConfig2
			}
			this.getSkuNameList()
			this.newFormVerification();
			this.formDataConfig.map(v => {
				if (v.showType == 'imgUploader') {
					this.uploadImgData = this.formData[v.prop];
				}
			});
		},
		methods:{
			handleChangeClose(e){
				this.$emit("schange")
			},
			//表单验证
			newFormVerification() {
				for (let i = 0; i < this.formDataConfig.length; i++) {
					let field = this.formDataConfig[i];
					this.validationRules(field, this);
				}
			},
			//提交保存数据
			handleChangeConfirm(formName) {
				//提交数据this.formData
				if(this.formData.skuName)
					this.formData.skuName = this.formData.skuName.toString()
				this.$refs[formName].validate(async valid => {
					if (valid) {
						this.$emit('fchange',this.formData);
						// this.$message.success('sku数据已保存！');
					} else {
						this.$message.error('请完善表单信息后重试！');
					}
				});
			},
			//图片上传成功回调
			successBack(list, res, name, ismultiple) {
				if (ismultiple) {
					this.uploadShowList = list;
				} else {
					const arr = { uid: '', url: res.data };
					this.uploadShowList = [arr];
				}
				this.setUploadImgUrl(this.uploadShowList, name, ismultiple);
			},
			//删除图片回调
			delImgBack(res, name, ismultiple) {
				this.setUploadImgUrl(res, name, ismultiple);
			},
			//设置上传图片地址
			setUploadImgUrl(res, name, ismultiple) {
				let imgurls = '';
				if (res.length > 0) {
					res.forEach((item, index) => {
						if (imgurls != '') imgurls += ',';
						imgurls += item.url;
					});
				}
				this.uploadImg = imgurls;
				this.oploadFilds = name;
				// if(ismultiple)
				// 	this.muploadImgData = imgurls
				if (this.oploadFilds) this.$set(this.formData, this.oploadFilds, this.uploadImg);
			},
			async getSkuNameList(){
				const res = await this.$axios("post","/sys/prodProp/list",{},true)
				if(res.length==0) return this.$message.error('请先去添加规格属性！');
				let arr = []
				res.map(s=>{
					arr.push({
						propValue:s.propName,
						valueId:s.propId,
						children:s.prodPropValues
					})
				})
				this.formDataConfig[0].option = arr
				this.$forceUpdate()
			},
			validationRules(editData, thiz) {
				if (editData.allowNull === false) return;
				let editType,
					valOption,
					textEditRule = [];
				if (editData.testType) editType = editData.testType;
				if (editData.testTypeOption) valOption = editData.testTypeOption;
				switch (editType) {
					case 'number':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									if (!value && value != 0 && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									let val = /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
									if (!val) {
										callback(new Error('请输入合法的数字'));
									} else if (valOption) {
										if ('min' in valOption) {
											if (value < valOption.min) callback(new Error('输入值不能小于' + valOption.min));
											else callback();
										} else if ('max' in valOption) {
											if (value > valOption.max) callback(new Error('输入值不能大于' + valOption.max));
											else callback();
										} else if ('range' in valOption) {
											if (value > valOption.range[1] || value < valOption.range[0])
												callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
											else callback();
										} else callback();
									} else callback(new Error('请指定输入值的范围!'));
								},
								trigger: ['blur', 'change']
							}
						];
						break;
					case 'digits':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									if (!Number.isInteger(Number(value))) {
										callback(new Error('请输入整数'));
									} else if (valOption) {
										if ('min' in valOption) {
											if (value < valOption.min) callback(new Error('输入值不能小于' + valOption.min));
											else callback();
										} else if ('max' in valOption) {
											if (value > valOption.max) callback(new Error('输入值不能大于' + valOption.max));
											else callback();
										} else if ('range' in valOption) {
											if (value > valOption.range[1] || value < valOption.range[0])
												callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
											else callback();
										} else callback();
									} else callback(new Error('请指定输入值的范围!'));
								},
								trigger: ['blur', 'change']
							}
						];
						break;
					case 'percent':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									let reg = /^((\d+\.?\d*)|(\d*\.\d+))\%$/;
									if (!reg.test(value)) {
										callback(new Error('请输入百分比'));
									} else {
										callback();
									}
								},
								trigger: 'blur'
							}
						];
						break;
					case 'mobile':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									let length = value.length,
										mobile = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[8-9]{1}))+\d{8})$/; //验证手机
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									if (length == 11 && mobile.test(value)) {
										callback();
									} else {
										return callback(new Error('手机号码格式错误！'));
									}
								},
								trigger: 'blur'
							}
						];
						break;
					case 'phone':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									let length = value.length,
										phone = /^0\d{2,4}-?\d{7,8}$/, //验证固定电话
										salephone = /^((400)|(800))[0-9]{7}$/;
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									if ((length >= 11 && length <= 13 && phone.test(value)) || (length == 10 && salephone.test(value))) {
										callback();
									} else {
										return callback(new Error('电话号码格式错误！（固定电话格式“区号-电话”）'));
									}
								},
								trigger: 'blur'
							}
						];
						break;
					case 'email':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									let emReg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									if (emReg.test(value)) {
										callback();
									} else {
										return callback(new Error('请输入正确的邮箱格式！'));
									}
								},
								trigger: 'blur'
							}
						];
						break;
					case 'postal':
						textEditRule = [
							{
								required: true,
								validator: (rule, value, callback) => {
									let ptlReg = /^[a-zA-Z0-9 ]{3,12}$/g;
									if (!value && !editData.allowNull) {
										return callback(new Error(editData.label + '不能为空'));
									}
									if (ptlReg.test(value)) {
										callback();
									} else {
										return callback(new Error('请输入正确的邮政编码！'));
									}
								},
								trigger: 'blur'
							}
						];
						break;
					default:
						textEditRule = [
							{
								required: true,
								message: '请输入' + editData.label,
								trigger: ['blur', 'change']
							}
						];
						break;
				}
				thiz.$set(thiz.rules, editData.prop, textEditRule);
			}
		}
	}
</script>

<style scoped>

</style>
